<template>
  <div class="text-center ma-2">
    <transition name="slide-fade">
      <v-snackbar v-if="snackbar" v-model="snackbar" right :timeout="timeout" class="snack-small background-infinity">
        <span class="color-text-infinity" v-html="rawHtmlText" />
        <v-btn
          color="black"
          class="mx-4"
          height="35"
          width="100"
          @click="snackbar = false"
        >
          Fechar
        </v-btn>
      </v-snackbar>
    </transition>
  </div>
</template>

<script>

export default {
  data: () => ({
    snackbar: true,
    timeout: (1000 * 60 ) * 24 // (24 horas)
  }),
  computed: {
    rawHtmlText() {
      const ambiente = this.translate(this.$store.getters.getAmbiente);
      if (ambiente == 'homologação' || ambiente == 'desenvolvimento') {
        this.snackbar = true;
        return `<b>Atenção: você está conectado no ambiente de ${ambiente}</b>.`;
      } else {
        this.snackbar = false;
        return '';
      }
    }
  },
  methods: {
    translate(ambiente) {
      if (!ambiente) return null;
      switch (ambiente.toLowerCase()) {
        case 'staging':
          return 'homologação';
        case 'local':
          return 'desenvolvimento';
        default:
          return null;
      }
    }
  }
}

</script>

<style>

.slide-fade-leave-active {
  transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to {
  transform: translateY(30px);
  opacity: 0;
}
.snack-small .v-snack__content {
  padding: 0 10px !important;
}
.snack-bg-primary {
  padding: 3px !important;
  border-radius: 50px !important;
}
.background-infinity .v-snack__content {
  animation: changeBackgroundColor 7s infinite;
}
.color-text-infinity {
  animation: changeTextColor 7s infinite;
}
@keyframes changeBackgroundColor {
  0% {
    background-color: #001F3F;
  }
  50% {
    background-color: #FF4136;
  }
  100% {
    background-color: #001F3F;
  }
}
@keyframes changeTextColor {
  0% {
    color: black;
  }
  50% {
    color: #FFF;
  }
  100% {
    color: #FFF;
  }
}

</style>